<template>
  <div class="home-view">
    <div @click="showDIB = true" class="btn">DIB Example</div>
    <div v-if="showDIB" @click="showDIB = false" class="dib-iframe-backdrop">
      <iframe class="dib-iframe" src="https://staging.directinvest.app//?widget=JSUWHLQLJWRJTEGXYOJLNUHNT"></iframe>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      showDIB: false,
    }
  },
}
</script>

<style>
.home-view {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.btn {
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  background-color: black;
  color: white;
  width: 150px;
  cursor: pointer;
  margin: 20px;
}
.dib-iframe-backdrop {
  position: absolute;
  background-color: rgba(124, 124, 124, 0.433);
  min-height: 100vh;
  min-width: 100vw;
  cursor: pointer;
  display: flex;
  justify-content: center;
}
.dib-iframe {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 120px;
  height: 700px;
  width: 500px;
  max-height: calc(100vh - 140px);
  max-width: calc(100% - 40px);
  background-color: white;
  border: none;
  border-radius: 15px;
}
</style>
